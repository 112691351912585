<template>
  <div class="login">
    <h1>BUG FINDER</h1>
    <input v-model="email" type="text" placeholder="email" />
    <input v-model="password" type="password" placeholder="password" />
    <button @click="login">Log-in</button>
  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",//"anas@marensolutions.com",
      password: "",//"1122",
    };
  },
  methods: {
    async login(e) {
      e.preventDefault();
      if(!this.email||!this.password){
          return alert("please provide credentials")
      }
      try {
          let response = await this.$store.dispatch("auth/loginReq", {
        email: this.email,
        password: this.password,
      });
      if (response && response.status == 1000) {
        this.$router.push({ name: "Home" });
      } else if (response && response.status == 1001) {
        alert(response.message);
      } else {
        alert("Login Failed");
      }
      } catch (error) {
          alert(error.message||"Login Failed");
      }
    },
  },
};
</script>
<style scoped>
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-width: 300px;
  min-height: 300px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}
.login h1 {
  margin-bottom: 20px;
}
.login input {
  width: 80%;
  padding: 10px;
  margin: 5px 0;
  background-color: #e0e0e0;
  border: none;
  border-bottom: 1px solid #bebebe;
  outline: none;
  font-size: 15px;
}
.login button {
  background-color: #bebebe5d;
  border-radius: 10px;
  margin-top: 20px;
  border: none;
  outline: none;
  padding: 15px 25px;
  font-size: 16px;
  color: #2c3e50;
}
.login button:hover{
  background-color: #2c3e50;
  color: #f4f4f4;
}
</style>