<template>
    <div class="auth">
        <Login />
    </div>
</template>

<script>
import Login from "../components/Login.vue"
export default {
    name:"Auth",
    
        
    components:{Login}
}
</script>
<style scoped>
.auth{
    width: 100;
    min-height: 100vh;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>